import { CSSObject } from '@emotion/css/create-instance';
import { Colors } from 'dg-web-shared/ui/vars';
import { Typo } from 'dg-web-shared/ui/typo.ts';

export namespace ParkingcardTypo {
    interface BasicFont extends CSSObject {
        fontFamily: string;
        fontWeight: number;
    }

    interface Action extends BasicFont {
        color: string;
        fontSize: string;
        lineHeight: string;
        letterSpacing: string;
        textTransform: 'uppercase';
    }

    export const action: Action = {
        ...Typo.robotoMedium,
        color: Colors.blue,
        fontSize: '15px',
        lineHeight: '16px',
        letterSpacing: '0.015em',
        textTransform: 'uppercase',
    };

    export const caption = {
        ...Typo.robotoRegular,
        fontSize: '13px',
        lineHeight: '16px',
        letterSpacing: '0.015em',
    };

    export const tBody = {
        ...Typo.robotoRegular,
        fontSize: '13px',
        lineHeight: '16px',
        letterSpacing: '0.01em',
    };

    export const heading3 = {
        ...Typo.robotoMedium,
        fontSize: '32px',
        lineHeight: '40px',
    };

    export const heading4 = {
        ...Typo.robotoBold,
        fontSize: '18px',
        lineHeight: '20px',
    };

    export const sCaptionTopCorrectionPx = 2;
}
