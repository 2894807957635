import { useState } from 'react';
import { Formatter } from 'dg-web-shared/lib/Date';
import * as Flux from 'dg-web-shared/lib/Flux';
import { useStore } from 'dg-web-shared/lib/Flux';
import * as NumberFormatter from 'dg-web-shared/lib/NumberFormatter';
import { Nbsp } from 'dg-web-shared/lib/Punctuation';
import { HasChildren } from 'dg-web-shared/lib/ReactHelpers';
import { Translation } from 'dg-web-shared/lib/Text';
import {
    IconButton,
    IconButtonType,
} from 'dg-web-shared/tb-ui/buttons/IconButton.tsx';
import * as Icons16 from 'dg-web-shared/ui/icons/Icons16';
import * as MetaServerState from '../../account/meta/state/MetaServerState';
import { SlideInPortalId } from '../../account/root/components/PortalSlidein';
import * as SettingsState from '../../common/state/SettingsState';
import * as LayoutState from '../../layout/state/LayoutState';
import { columnsVariant } from '../../layout/utils/columnsVariant';
import { ResponsiveProperties } from '../../layout/utils/ResponsiveProperties';
import { responsiveVariant } from '../../layout/utils/responsiveVariant';
import { StartedCheckinByPlateOnThisDeviceState } from '../../park-create/components/ParkCreate';
import { CheckinByPlateSlideIn } from '../checkin-by-plate/CheckinByPlateSlideIn';
import { transactionsListTexts } from '../i18n/TransactionsListTexts';
import * as TransactionsListState from '../state/TransactionsListState';
import { OffstreetCheckinDetailSlideIn } from './OffstreetCheckinDetailSlideIn';
import { PermitDetailSlideIn } from './PermitDetailSlideIn';
import { PermitPurchaseConfirmationSlideIn } from './PermitPurchaseConfirmationSlideIn';
import {
    BlockColor,
    ContractItemBlock,
} from '../../account/root/components/ItemBlock';
import { stringToFourCharacterBlocks } from 'dg-web-shared/lib/StringConversions';
import { ButtonText, ModalInfoBox } from '../../ui/modals/Confirmable';
import { Localized } from '../../common/components/Localized';
import { css } from '@emotion/css';
import { CSSInterpolation } from '@emotion/css/create-instance';
import { Typo } from 'dg-web-shared/ui/typo.ts';
import { Colors } from 'dg-web-shared/ui/vars';
import { CurrentLoginState } from '../../common/state/CurrentLoginState';
import { paper } from 'dg-web-shared/tb-ui/paper.ts';
import {
    TicketError,
    TicketResetInfo,
} from '../../park-create/ticket/ParkCreateTicketPlus';
import { Ticket } from '../../park-create/ticket/TicketSlideIn';
import { SharedTicketSlideIn } from '../../park-create/ticket/SharedTicketSlideIn';
import { RedWarnIcon24 } from '../../common/components/RedWarnIcon';
import {
    OffstreetCheckin,
    OnstreetCheckin,
    PaidTicket,
    PendingTicket,
    Permit,
    useTransactionList,
} from '../TransactionsListContext';
import { ParkingpayAsyncLoadedSection } from '../../common/components/ParkingpayAsyncLoadedSection';
import { DateTime } from 'luxon';
import { useServerFetch } from '../../hooks/ServerStateHooks';
import {
    TransactionCost,
    TransactionCostTag,
} from 'dg-web-shared/model/TransactionList';

export interface TransactionsListTexts {
    title: Translation;
    needTransactionHeader: Translation;
    activeItemsHeader: Translation;
    futureItemsHeader: Translation;
    expiredItemsHeader: Translation;
    validFrom: (from: string) => string;
}

interface ItemProps<T> {
    item: T;
    update: Flux.Updater;
    settings: SettingsState.State;
}

const permitItemIdentifiers = (item: Permit) =>
    item.licensePlates.concat(item.badges).join(', ');

export namespace Actions {
    export const openOnstreetCheckinDetail = (
        store: Flux.Store,
        id: number,
    ) => {
        if (
            StartedCheckinByPlateOnThisDeviceState.get(store)
                .checkinByPlateId === id
        ) {
            LayoutState.Mobile.stateWrite(store, {
                transactionSlideinOpen: false,
                accountSlideinOpen: false,
            });
        } else {
            TransactionsListState.Detail.stateWrite(store, {
                parkTransactionId: id,
            });
        }
        return 'openParkTransactionDetail';
    };
    export const openOffstreetCheckinDetail = (
        store: Flux.Store,
        id: number,
    ) => {
        TransactionsListState.Detail.stateWrite(store, {
            offstreetCheckinId: id,
        });
        return 'openOffstreetTransactionDetail';
    };

    export const openPermitDetail = (store: Flux.Store, permitId: number) => {
        TransactionsListState.Detail.stateWrite(store, {
            permitId,
        });

        return 'openPermitDetail';
    };
}

function ActiveOnstreetCheckinItem({
    item,
    update,
}: {
    item: OnstreetCheckin;
    update: Flux.Updater;
}) {
    return (
        <ContractItemBlock
            iconName="onstreet"
            title={`${item.extZoneCode} ${item.zoneName}`}
            subTitle={item.licensePlate}
            onClick={() =>
                update(store =>
                    Actions.openOnstreetCheckinDetail(store, item.contractId),
                )
            }
            iconColor={BlockColor.BLUE}
        />
    );
}

function ActiveTicketItem({
    item,
    onClick,
}: {
    item: PendingTicket;
    onClick: () => void;
}) {
    return (
        <ContractItemBlock
            iconName="ticket"
            title={item.zoneName}
            subTitle={item.ticketApiString}
            onClick={onClick}
            iconColor={BlockColor.BLUE}
        />
    );
}

function ActivePermitItem({
    item,
    update,
}: {
    item: Permit;
    update: Flux.Updater;
}) {
    return (
        <ContractItemBlock
            iconName="permit"
            iconColor={BlockColor.BLUE}
            title={item.permitTypeName}
            subTitle={permitItemIdentifiers(item)}
            rightTitle={<ActiveItemRightTitle item={item} />}
            onClick={() =>
                update(store =>
                    Actions.openPermitDetail(store, item.contractId),
                )
            }
        />
    );
}

function ActiveItemRightTitle({ item }: { item: Permit }): JSX.Element | null {
    if (item.showWarning) {
        return <RedWarnIcon24 />;
    }
    const validTo = DateTime.fromISO(item.validTo);
    if (validTo < DateTime.fromISO('2030-01-01')) {
        return <>{Formatter.dayMonthYear(validTo)}</>;
    }
    return null;
}

function ActivePermitThatNeedsTransactionItem({
    item,
    update,
}: {
    item: Permit;
    update: Flux.Updater;
}) {
    const validTo = DateTime.fromISO(item.validTo);
    return (
        <ContractItemBlock
            iconName="permit"
            iconColor={BlockColor.GREY}
            title={item.permitTypeName}
            subTitle={permitItemIdentifiers(item)}
            rightTitle={
                validTo > DateTime.fromISO('2030-01-01')
                    ? Nbsp
                    : Formatter.dayMonthYear(validTo)
            }
            onClick={() =>
                update(store =>
                    Actions.openPermitDetail(store, item.contractId),
                )
            }
        />
    );
}

function FuturePermitItem(p: ItemProps<Permit>) {
    return (
        <ContractItemBlock
            iconName="permit"
            iconColor={BlockColor.GREY}
            title={p.item.permitTypeName}
            subTitle={permitItemIdentifiers(p.item)}
            rightTitle={
                p.item.showWarning ? (
                    <RedWarnIcon24 />
                ) : (
                    texts(p).validFrom(
                        Formatter.dayMonthYear(
                            DateTime.fromISO(p.item.validFrom),
                        ),
                    )
                )
            }
            onClick={() =>
                p.update(store =>
                    Actions.openPermitDetail(store, p.item.contractId),
                )
            }
        />
    );
}

function ExpiredOnstreetTransactionItem({
    item,
    update,
}: {
    item: OnstreetCheckin;
    update: Flux.Updater;
}) {
    return (
        <ContractItemBlock
            iconName={item.extZoneCode ? 'onstreet' : 'offstreet'}
            iconColor={BlockColor.GREY}
            title={`${item.extZoneCode ? item.extZoneCode : ''} ${
                item.zoneName
            }`}
            rightTitle={NumberFormatter.numberToPrice(item.price)}
            subTitle={item.licensePlate}
            onClick={() =>
                update(store =>
                    Actions.openOnstreetCheckinDetail(store, item.contractId),
                )
            }
        />
    );
}

function ExpiredOffstreetTransactionItem({
    item,
    update,
}: {
    item: OffstreetCheckin;
    update: Flux.Updater;
}) {
    return (
        <ContractItemBlock
            iconName="offstreet"
            iconColor={BlockColor.GREY}
            title={item.zoneName}
            rightTitle={NumberFormatter.numberToPrice(item.price)}
            subTitle={stringToFourCharacterBlocks(item.badgeLabelNr)}
            onClick={() =>
                update(store =>
                    Actions.openOffstreetCheckinDetail(store, item.contractId),
                )
            }
        />
    );
}

function ExpiredTicketItem({
    item,
    onClick,
}: {
    item: PaidTicket;
    onClick: () => void;
}) {
    return (
        <ContractItemBlock
            iconName="ticket"
            iconColor={BlockColor.GREY}
            title={item.zoneName}
            rightTitle={NumberFormatter.numberToPrice(item.price)}
            subTitle={item.ticketApiString}
            onClick={onClick}
        />
    );
}

function ExpiredPermitItem({
    item,
    update,
}: {
    item: Permit;
    update: Flux.Updater;
}) {
    return (
        <ContractItemBlock
            iconName="permit"
            iconColor={BlockColor.GREY}
            title={item.permitTypeName}
            subTitle={permitItemIdentifiers(item)}
            onClick={() =>
                update(store =>
                    Actions.openPermitDetail(store, item.contractId),
                )
            }
        />
    );
}

interface ItemListProps extends HasChildren {
    title: string;
}

const ItemList = (p: ItemListProps) => (
    <>
        <div
            className={css({
                ...Typo.listheader2,
                textTransform: 'uppercase',
                color: Colors.blue,
                background: Colors.rgba(Colors.black, 0.08),
                height: '30px',
                padding: '0 24px',
                display: 'flex',
                alignItems: 'center',
            })}
        >
            {p.title}
        </div>
        {p.children}
    </>
);
const texts = (p: { settings: SettingsState.State }) =>
    transactionsListTexts[p.settings.language];

const isMobile = (p: { layout: LayoutState.State }) =>
    new ResponsiveProperties(p).mobile;

function TransactionDownload({ language }: { language: string }) {
    return (
        <IconButton
            small={true}
            icon16px={true}
            link={`ui-api/customer-account/deposits/monthly-reports/xls-export?lang=${language}`}
            type={IconButtonType.regular}
            icon={Icons16.download}
            onClick={() => null}
        />
    );
}

function Header() {
    const { storeState } = useStore(store => ({
        settings: new SettingsState.StateSlice(store).state,
        layout: new LayoutState.StateSlice(store).state,
        currentLogin: CurrentLoginState.get(store),
    }));
    if (isMobile({ layout: storeState.layout })) {
        return null;
    }
    return (
        <div
            className={css({
                height: '48px',
                background: Colors.darkblue,
                display: 'flex',
            })}
        >
            <div
                className={css({
                    color: Colors.white,
                    ...Typo.heading1,
                    paddingLeft: '24px',
                    paddingTop: `${14 - Typo.heading1TopCorrection}px`,
                    flex: 1,
                })}
            >
                {texts({ settings: storeState.settings }).title()}
            </div>
            {storeState.currentLogin.data?.loginRole ===
                CurrentLoginState.LoginRole.ADMIN && (
                <TransactionDownload language={storeState.settings.language} />
            )}
        </div>
    );
}

const NoTransactionMessage = () => (
    <>
        <div
            className={css({
                ...Typo.body,
                color: Colors.typo1,
                padding: '48px 24px',
                textAlign: 'center',
            })}
        >
            <Localized
                de="Keine aktuelle Transaktion"
                fr="Aucune transaction récente"
                it="Nessuna transazione recente"
                en="No recent transaction"
            />
        </div>
        <div
            className={css({
                ...Typo.robotoMedium,
                fontSize: '13px',
                color: Colors.typo1,
                padding: '0px 24px',
                textAlign: 'center',
            })}
        >
            <Localized
                de="Der vollständige Verlauf aller Transaktionen"
                fr="L'historique complet de toutes les transactions"
                it="Lo storico completo di tutte le transazioni"
                en="The complete history of all transactions"
            />
            <br />
            <Localized
                de="ist unter KONTO > KONTOAUSZUG verfügbar."
                fr="est disponible sous COMPTE > RELEVÉ DE COMPTE."
                it="è disponibile alla voce CONTO > ESTRATTO CONTO."
                en="is available under ACCOUNT > ACCOUNT STATEMENT."
            />
        </div>
    </>
);

export function TransactionsListSlideIns() {
    return (
        <div id={SlideInPortalId.TRANSACTION_LIST}>
            <CheckinByPlateSlideIn />
            <PermitDetailSlideIn />
            <PermitPurchaseConfirmationSlideIn />
            <OffstreetCheckinDetailSlideIn />
        </div>
    );
}

interface TransactionsListDisabledProps {
    meta: MetaServerState.State;
}

const transactionsListDisabled = (p: TransactionsListDisabledProps) =>
    p.meta.data && !p.meta.data.registrationComplete;

function singleColumnCss(p: TransactionsListDisabledProps): CSSInterpolation {
    return {
        height: '100%',
        overflow: 'hidden', // force border radius upon children
        opacity: transactionsListDisabled(p) ? 0.6 : 1,
        ...paper(0),
        borderRadius: 0,
        position: 'absolute',
        top: 0,
        bottom: 0,
        width: '100%',
    };
}

function tripleColumnCss(p: TransactionsListDisabledProps): CSSInterpolation {
    return {
        position: 'relative',
        height: '100%',
        ...paper(2),
        borderRadius: '2px',
        overflow: 'hidden', // force border radius upon children
        opacity: transactionsListDisabled(p) ? 0.6 : 1,
    };
}

export function TransactionsList() {
    const { storeState, update } = useStore(store => ({
        settings: new SettingsState.StateSlice(store).state,
        layout: new LayoutState.StateSlice(store).state,
        meta: new MetaServerState.StateSlice(store).state,
        login: CurrentLoginState.get(store),
    }));
    const [selectedTicketString, setSelectedTicketString] = useState<
        string | null
    >(null);

    const [transactionListState, refetchTransactionList] = useTransactionList();

    return (
        <div
            className={css(
                columnsVariant(storeState) === 'ThreeColumns'
                    ? tripleColumnCss(storeState)
                    : singleColumnCss(storeState),
            )}
        >
            <Header />
            <div
                className={css({
                    background: Colors.white,
                    position: 'absolute',
                    top:
                        responsiveVariant(storeState) === 'Mobile' ? 0 : '48px',
                    bottom: 0,
                    width: '100%',
                })}
            >
                {transactionsListDisabled(storeState) ? null : (
                    <ParkingpayAsyncLoadedSection
                        state={transactionListState}
                        renderSuccess={transactionList => (
                            <div
                                className={css({
                                    position: 'relative',
                                    height: '100%',
                                    overflowX: 'hidden',
                                    overflowY: 'scroll',
                                    WebkitOverflowScrolling: 'touch',
                                })}
                            >
                                {transactionList.active.length === 0 &&
                                    transactionList.needTransaction.length ===
                                        0 &&
                                    transactionList.future.length === 0 &&
                                    transactionList.expired.length === 0 && (
                                        <NoTransactionMessage />
                                    )}
                                {transactionList.active.length > 0 && (
                                    <ItemList
                                        title={texts(
                                            storeState,
                                        ).activeItemsHeader()}
                                    >
                                        {transactionList.active.map(item => {
                                            switch (item.type) {
                                                case 'parkTransaction':
                                                    return (
                                                        <ActiveOnstreetCheckinItem
                                                            key={
                                                                item.contractId
                                                            }
                                                            item={item}
                                                            update={update}
                                                        />
                                                    );
                                                case 'permit':
                                                    return (
                                                        <ActivePermitItem
                                                            key={
                                                                item.contractId
                                                            }
                                                            item={item}
                                                            update={update}
                                                        />
                                                    );
                                                case 'pendingTicket':
                                                    return (
                                                        <ActiveTicketItem
                                                            key={
                                                                item.ticketString
                                                            }
                                                            item={item}
                                                            onClick={() =>
                                                                setSelectedTicketString(
                                                                    item.ticketString,
                                                                )
                                                            }
                                                        />
                                                    );
                                            }
                                        })}
                                    </ItemList>
                                )}
                                {transactionList.needTransaction.length > 0 && (
                                    <ItemList
                                        title={texts(
                                            storeState,
                                        ).needTransactionHeader()}
                                    >
                                        {transactionList.needTransaction.map(
                                            item => (
                                                <>
                                                    {item.type === 'permit' && (
                                                        <ActivePermitThatNeedsTransactionItem
                                                            key={
                                                                item.contractId
                                                            }
                                                            item={item}
                                                            update={update}
                                                        />
                                                    )}
                                                </>
                                            ),
                                        )}
                                    </ItemList>
                                )}
                                {transactionList.future.length > 0 && (
                                    <ItemList
                                        title={texts(
                                            storeState,
                                        ).futureItemsHeader()}
                                    >
                                        {transactionList.future.map(item => (
                                            <>
                                                {item.type === 'permit' && (
                                                    <FuturePermitItem
                                                        key={item.contractId}
                                                        item={item}
                                                        update={update}
                                                        {...storeState}
                                                    />
                                                )}
                                            </>
                                        ))}
                                    </ItemList>
                                )}
                                {transactionList.expired.length > 0 && (
                                    <ItemList
                                        title={texts(
                                            storeState,
                                        ).expiredItemsHeader()}
                                    >
                                        {transactionList.expired.map(item => {
                                            switch (item.type) {
                                                case 'parkTransaction':
                                                    return (
                                                        <ExpiredOnstreetTransactionItem
                                                            key={
                                                                item.contractId
                                                            }
                                                            item={item}
                                                            update={update}
                                                        />
                                                    );
                                                case 'offstreetTransaction':
                                                    return (
                                                        <ExpiredOffstreetTransactionItem
                                                            key={
                                                                item.contractId
                                                            }
                                                            item={item}
                                                            update={update}
                                                        />
                                                    );
                                                case 'permit':
                                                    return (
                                                        <ExpiredPermitItem
                                                            key={
                                                                item.contractId
                                                            }
                                                            item={item}
                                                            update={update}
                                                        />
                                                    );
                                                case 'paidTicket':
                                                    return (
                                                        <ExpiredTicketItem
                                                            key={
                                                                item.ticketString
                                                            }
                                                            item={item}
                                                            onClick={() =>
                                                                setSelectedTicketString(
                                                                    item.ticketString,
                                                                )
                                                            }
                                                        />
                                                    );
                                            }
                                        })}
                                    </ItemList>
                                )}
                            </div>
                        )}
                    />
                )}
            </div>
            {!isMobile(storeState) ? <TransactionsListSlideIns /> : null}
            <TicketDetails
                ticketString={selectedTicketString}
                resetTicketString={() => setSelectedTicketString(null)}
                resetTransactionsList={refetchTransactionList}
            />
        </div>
    );
}

export function TicketDetails({
    ticketString,
    resetTicketString,
    resetTransactionsList,
}: {
    ticketString: string | null;
    resetTicketString: () => void;
    resetTransactionsList: () => void;
}) {
    const [showTicketResetInfo, setShowTicketResetInfo] = useState(false);

    const [ticketState, refetchTicketState] = useServerFetch<
        Ticket,
        { ticketString: string },
        TicketError
    >(
        context => ({
            url: `/ui-api/customer-account/ticket/${context.ticketString}`,
        }),
        ticketString ? { ticketString: ticketString } : null,
    );

    return (
        <>
            {ticketString && (
                <SharedTicketSlideIn
                    portal={SlideInPortalId.TRANSACTION_LIST}
                    ticketState={ticketState}
                    refetchTicketState={refetchTicketState}
                    onTicketReset={() => {
                        resetTicketString();
                        resetTransactionsList();
                        setShowTicketResetInfo(true);
                    }}
                    onTicketApproveSuccess={() => {
                        throw new Error(
                            'Ticket transaction list only contains already approved tickets',
                        );
                    }}
                    onClose={resetTicketString}
                />
            )}
            {showTicketResetInfo && (
                <TicketResetInfo
                    onClose={() => {
                        setShowTicketResetInfo(false);
                    }}
                />
            )}
        </>
    );
}

interface PriceDetailsProps {
    feeAmount: string;
    surchargeAmount?: string;
    paidAmount: string;
    onClose: () => void;
}

export function PriceDetailsInfoBox(props: {
    cost: TransactionCost;
    onClose: () => void;
}) {
    return (
        <ModalInfoBox
            confirmCaption={ButtonText.CLOSE}
            confirmCallback={props.onClose}
            titleCaption={
                <Localized
                    de="Preisdetails"
                    fr="Détails du prix"
                    it="Dettagli prezzo"
                    en="Price details"
                />
            }
        >
            <PriceDetails
                feeAmount={NumberFormatter.numberToPrice(
                    props.cost.serviceCostExcludingTax +
                        props.cost.serviceCostTax.amount,
                )}
                surchargeAmount={
                    props.cost.tag === TransactionCostTag.HAVING_SURCHARGES
                        ? NumberFormatter.numberToPrice(
                              props.cost.surchargeExcludingTax +
                                  props.cost.surchargeTax.amount,
                          )
                        : undefined
                }
                paidAmount={NumberFormatter.numberToPrice(
                    props.cost.paidAmount,
                )}
                onClose={props.onClose}
            />
        </ModalInfoBox>
    );
}

function PriceDetails(props: PriceDetailsProps) {
    return (
        <div
            className={css({
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                padding: '15px 0px',
            })}
        >
            <table
                className={css({
                    width: '100%',
                })}
            >
                <tr>
                    <td className={css({ textAlign: 'left' })}>
                        <Localized
                            de="Parkgebühr"
                            fr="Taxe de stationnement"
                            it="Tassa di parcheggio"
                            en="Parking Fee"
                        />
                    </td>

                    <td
                        className={css({
                            textAlign: 'right',
                            verticalAlign: 'bottom',
                        })}
                    >
                        {props.feeAmount}
                    </td>
                </tr>

                {!!props.surchargeAmount && (
                    <>
                        <tr>
                            <td
                                className={css({
                                    textAlign: 'left',
                                    height: '32px',
                                    verticalAlign: 'bottom',
                                })}
                            >
                                <Localized
                                    de="Zuschlag"
                                    fr="Supplément"
                                    it="Supplemento"
                                    en="Surcharge"
                                />
                            </td>

                            <td
                                className={css({
                                    textAlign: 'right',
                                    verticalAlign: 'bottom',
                                })}
                            >
                                {props.surchargeAmount}
                            </td>
                        </tr>
                    </>
                )}

                <tr className={css({ fontWeight: 600 })}>
                    <td
                        className={css({
                            textAlign: 'left',
                            verticalAlign: 'bottom',
                            minWidth: '180px',
                            height: '32px',
                        })}
                    >
                        <Localized
                            de="Total"
                            fr="Total"
                            it="Totale"
                            en="Total"
                        />
                    </td>

                    <td
                        className={css({
                            textAlign: 'right',
                            verticalAlign: 'bottom',
                        })}
                    >
                        {props.paidAmount}
                    </td>
                </tr>
            </table>
        </div>
    );
}
