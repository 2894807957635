import 'core-js/stable';

import {
    App,
    INVALID_SCAN_PATH,
    TWINT_QR_CODE_SCAN_PATH,
} from './app/components/App';
import * as Sentry from './utils/Sentry';
import * as fluxInstance from './fluxInstance';
import * as Update from './utils/Update';
import { Store } from 'dg-web-shared/lib/Flux';
import { GeolocationState } from './common/state/GeolocationState';
import { logAction } from './utils/ActionLog';
import { FCMTokenState } from './common/state/FCMTokenState';
import { createRoot } from 'react-dom/client';
import { Base64 } from 'dg-web-shared/common/utils/Base64.ts';
import React from 'react';
import {
    findMatchingUrlAction,
    QrScanActionType,
} from 'dg-web-shared/common/utils/QrScanPatternMatching.tsx';
import 'inobounce';

Sentry.init();

/*
let's not use RUM for the moment, as this might cause issues in the app
datadogRum.init({
    applicationId: 'c7df0a35-9577-435e-afeb-27f2c61de1f8',
    clientToken: 'pubbd3dde716870ebd9e4ef873fb1f3cf87',
    site: 'datadoghq.com',
    service: 'Parkingpay',
    env: envIsProduction() ? 'prod' : 'test',
    sampleRate: 2,
    trackInteractions: true,
});
*/

const enum NativeDeviceMessageType {
    location = 'location',
    locationError = 'locationError',
    FCMToken = 'FCMToken',
    DatatransCallback = 'DatatransCallback',
    TwintCallback = 'TwintCallback',
    TicketCallback = 'TicketCallback',
    PushNotificationCallback = 'PushNotificationCallback',
    QRScannerCallback = 'QRScannerCallback',
}

type MessageFromNativeDeviceCallback = (
    type: NativeDeviceMessageType,
    message: string,
) => void;

type WindowWithBridge = {
    messageFromNativeDevice: MessageFromNativeDeviceCallback;
} & Window &
    typeof globalThis;

(<WindowWithBridge>window).messageFromNativeDevice = (
    type: NativeDeviceMessageType,
    message: string,
) => {
    fluxInstance.flux.update((store: Store): string => {
        switch (type) {
            case NativeDeviceMessageType.location:
                return GeolocationState.setCoordinatesIfChanged(
                    store,
                    JSON.parse(message),
                );
            case NativeDeviceMessageType.locationError:
                return GeolocationState.setError(store);
            case NativeDeviceMessageType.FCMToken:
                return FCMTokenState.stateWrite(store, JSON.parse(message));
            case NativeDeviceMessageType.DatatransCallback: {
                // deprecated
                console.error('Tried to call deprecated DatatransCallback');
                return 'ok';
            }
            case NativeDeviceMessageType.TwintCallback: {
                // deprecated
                console.error('Tried to call deprecated TwintCallback');
                return 'ok';
            }
            case NativeDeviceMessageType.PushNotificationCallback:
                store.update(store => {
                    logAction(store, 'receive-push-callback');
                    return 'receive-push-callback';
                });
                return 'ok';
            case NativeDeviceMessageType.TicketCallback:
                logAction(store, 'receive-ticket-callback', message);
                window.location.href = `/ticket/${message}`;
                return 'ok';
            case NativeDeviceMessageType.QRScannerCallback: {
                const qrCodeString = extractTwintQrCode(message);
                if (qrCodeString) {
                    logAction(store, 'scan-twint-qr', {
                        qrCodeString: qrCodeString,
                    });
                    window.location.href = `/${TWINT_QR_CODE_SCAN_PATH}/${Base64.encodeURLSafe(
                        qrCodeString,
                    )}`;
                    return 'ok';
                }
                const urlAction = findMatchingUrlAction(message);
                switch (urlAction.action) {
                    case QrScanActionType.PARSING_FAILED:
                        logAction(store, 'scan-unknown', { scan: message });
                        window.location.href = '/' + INVALID_SCAN_PATH;
                        return 'ok';
                    case QrScanActionType.SCAN_UNKNOWN:
                        logAction(store, 'scan-unknown', {
                            redirectUrl: message,
                        });
                        window.location.href = '/' + INVALID_SCAN_PATH;
                        return 'ok';
                    case QrScanActionType.SCAN_TICKET_URL:
                    case QrScanActionType.SCAN_PERMIT_URL:
                    case QrScanActionType.SCAN_ZONE_URL:
                    case QrScanActionType.SCAN_RECEIPT_URL: {
                        const indexIdentification =
                            urlAction.url.pathname.lastIndexOf('/') + 1;
                        logAction(store, urlAction.action, {
                            redirectUrl: message,
                            identification:
                                urlAction.url.pathname.substring(
                                    indexIdentification,
                                ),
                        });
                        window.location.href =
                            urlAction.url.pathname + urlAction.url.search;
                        return 'ok';
                    }
                }
                return 'ok';
            }
            default:
                return 'unknownMessageFromDevice';
        }
    });
};

Update.init(fluxInstance.flux);

const container = document.getElementById('app-container');
const root = createRoot(container!);
root.render(
    React.createElement(App, {
        allState: fluxInstance.flux,
    }),
);

logAction(fluxInstance.flux, 'app-loaded', window.location.search);

const TWINT_ZURICH_SPECIAL_URL = 'https://www.twint.ch/qr_scan?twint=02:';

function extractTwintQrCode(message: unknown): string | null {
    if (typeof message != 'string') {
        return null;
    } else if (message.startsWith('02:')) {
        return message;
    } else if (message.startsWith(TWINT_ZURICH_SPECIAL_URL)) {
        return message.slice(TWINT_ZURICH_SPECIAL_URL.length - 3);
    } else {
        return null;
    }
}
