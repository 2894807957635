export namespace Base64 {
    /**
     * Encodes to an url safe base64 string (base64url) based on
     * https://datatracker.ietf.org/doc/html/rfc4648#section-5
     * @param str string to encode to base64url
     */
    export function encodeURLSafe(str: string): string {
        return btoa(str).replace(/[+/]/g, toReplaceString => {
            switch (toReplaceString) {
                case '+':
                    return '-';
                case '/':
                    return '_';
                default:
                    return toReplaceString;
            }
        });
    }

    export function decodeURLSafe(str: string): string {
        const base64String = str.replace(/[-_]/g, toReplaceString => {
            switch (toReplaceString) {
                case '-':
                    return '+';
                case '_':
                    return '/';
                default:
                    return toReplaceString;
            }
        });
        return atob(base64String);
    }
}
